import React from "react";
import { Pie } from "react-chartjs-2";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography, Grid } from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebaseConfig"; // adjust path if needed

// Register ChartJS components
ChartJS.register(ArcElement, Tooltip, Legend);

const Statistics = ({ transactions, categories }: any) => {
  // Helper to get a category's color from categories
  const getCategoryColor = (categoryName: string) => {
    const cat = categories.find((c: any) => c.name === categoryName);
    return cat?.color || "#000000"; // Default to black if no color is found
  };

  // Calculate total amount per category for debit transactions
  const debitTotals = transactions
    .filter((t: any) => t.type === "debit")
    .reduce((acc: any, t: any) => {
      acc[t.category] = (acc[t.category] || 0) + t.amount;
      return acc;
    }, {});

  // Calculate total amount per category for credit transactions
  const creditTotals = transactions
    .filter((t: any) => t.type === "credit")
    .reduce((acc: any, t: any) => {
      acc[t.category] = (acc[t.category] || 0) + t.amount;
      return acc;
    }, {});

  // Prepare data for debit pie chart and table
  const debitLabels = Object.keys(debitTotals);
  const debitData = Object.values(debitTotals);
  const debitColors = debitLabels.map((label) => getCategoryColor(label));
  const debitRows = debitLabels.map((category, index) => ({
    id: index,
    category,
    totalAmount: debitTotals[category].toFixed(2), // Format numbers properly
  }));

  // Prepare data for credit pie chart and table
  const creditLabels = Object.keys(creditTotals);
  const creditData = Object.values(creditTotals);
  const creditColors = creditLabels.map((label) => getCategoryColor(label));
  const creditRows = creditLabels.map((category, index) => ({
    id: index,
    category,
    totalAmount: creditTotals[category].toFixed(2),
  }));

  const [totalTrainingInvestment, setTotalTrainingInvestment] = useState(0);

  useEffect(() => {
    const fetchInvestment = async () => {
      const snapshot = await getDocs(collection(db, "trainings"));
      const total = snapshot.docs.reduce((sum, doc) => {
        const data = doc.data();
        return sum + Number(data.totalInvestmentCL || 0);
      }, 0);
      setTotalTrainingInvestment(total);
    };
    fetchInvestment();
  }, []);

  // Columns for the DataGrid tables
  const columns = [
    { field: "category", headerName: "Category", width: 200 },
    { field: "totalAmount", headerName: "Total Amount", width: 150 },
  ];

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={4}>
        {/* Debit Statistics (Left Side) */}
        <Grid item xs={12} md={6}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Debit Transactions
          </Typography>
          <Box display="flex" justifyContent="center">
            <Pie
              data={{
                labels: debitLabels,
                datasets: [
                  {
                    data: debitData,
                    backgroundColor: debitColors,
                  },
                ],
              }}
              options={{ maintainAspectRatio: false }}
              width={200} // Make pie chart smaller
              height={200}
            />
          </Box>
          <Box mt={2}>
            <DataGrid rows={debitRows} columns={columns} autoHeight />
            <Typography variant="h6" sx={{ mb: 2 }}>
              Total investiție stagii adulți: {totalTrainingInvestment} RON
            </Typography>
          </Box>
        </Grid>

        {/* Credit Statistics (Right Side) */}
        <Grid item xs={12} md={6}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Credit Transactions
          </Typography>
          <Box display="flex" justifyContent="center">
            <Pie
              data={{
                labels: creditLabels,
                datasets: [
                  {
                    data: creditData,
                    backgroundColor: creditColors,
                  },
                ],
              }}
              options={{ maintainAspectRatio: false }}
              width={200}
              height={200}
            />
          </Box>
          <Box mt={2}>
            <DataGrid rows={creditRows} columns={columns} autoHeight />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Statistics;
