import React from "react";
import { GridRenderEditCellParams } from "@mui/x-data-grid";
import { Select, MenuItem, SelectChangeEvent } from "@mui/material";

interface EditCategoryCellProps extends GridRenderEditCellParams {
  categories: any[];
}

const EditCategoryCell: React.FC<EditCategoryCellProps> = (props) => {
  const { id, value, field, api, categories } = props;

  const handleChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value as string;

    // Use setEditCellValue to update the grid cell value
    api.setEditCellValue({ id, field, value: newValue });

    // Force the grid to update and trigger processRowUpdate
    api.stopCellEditMode({ id, field });
  };

  return (
    <Select value={value || ""} onChange={handleChange} fullWidth>
      {categories.map((category) => (
        <MenuItem key={category.id} value={category.name}>
          {category.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default EditCategoryCell;
