import { useDropzone } from "react-dropzone";
import { CloudUpload } from "@mui/icons-material";
import { Paper, Typography, Box } from "@mui/material";

const FileUploader = ({ handleFileUpload }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: true,
    accept: {
      "text/plain": [".txt"], // Correct way to accept .txt files
    }, // Accept MT940 text files
    onDrop: (acceptedFiles) =>
      handleFileUpload({ target: { files: acceptedFiles } }),
  });

  return (
    <Paper
      {...getRootProps()}
      sx={{
        border: "2px dashed #1976d2",
        padding: "20px",
        textAlign: "center",
        cursor: "pointer",
        "&:hover": { backgroundColor: "#f5f5f5" },
      }}
    >
      <input {...getInputProps()} />
      <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
        <CloudUpload fontSize="large" color="primary" />
        {isDragActive ? (
          <Typography variant="body1">Drop the files here...</Typography>
        ) : (
          <Typography variant="body1">
            Drag & drop your MT940 files here, or click to browse
          </Typography>
        )}
      </Box>
    </Paper>
  );
};

export default FileUploader;
