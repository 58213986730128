// Full corrected TrainingPage with Autocomplete per participant
import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tabs,
  Tab,
  Autocomplete,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Helmet } from "react-helmet";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { useUser } from "../../contexts/UserContext";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";

const ramuriDeVarsta = [
  "Lupișori",
  "Temerari",
  "Exploratori",
  "Seniori",
  "Scouteri",
  "Altele",
];

const COLORS = [
  "#FFBB28",
  "#00C49F",
  "#0088FE",
  "#AF19FF",
  "#FF8042",
  "#FF6666",
];

const TrainingPage = () => {
  const [trainings, setTrainings] = useState([]);
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(0);
  const [allParticipants, setAllParticipants] = useState([]);
  const { user } = useUser();

  const initialState = {
    id: "",
    name: "",
    date: new Date().toISOString().split("T")[0],
    location: "",
    participants: [],
    feePerParticipant: "",
    totalInvestmentCL: "",
    notes: "",
    addedBy: user?.displayName || "",
  };

  const [newTraining, setNewTraining] = useState({ ...initialState });

  const fetchTrainings = async () => {
    const querySnapshot = await getDocs(collection(db, "trainings"));
    const items = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setTrainings(items);

    const all = items.flatMap((t: any) => t.participants || []);
    const unique = all.filter(
      (p, index, self) =>
        index ===
        self.findIndex(
          (other) => other.name === p.name && other.branch === p.branch
        )
    );
    setAllParticipants(unique);
  };

  useEffect(() => {
    fetchTrainings();
  }, []);

  const handleChange = (e) => {
    setNewTraining({ ...newTraining, [e.target.name]: e.target.value });
  };

  const handleParticipantChange = (index, field, value) => {
    const updatedParticipants = [...newTraining.participants];
    updatedParticipants[index][field] = value;
    setNewTraining({ ...newTraining, participants: updatedParticipants });
  };

  const addParticipant = () => {
    setNewTraining({
      ...newTraining,
      participants: [...newTraining.participants, { name: "", branch: "" }],
    });
  };

  const removeParticipant = (index) => {
    const updated = [...newTraining.participants];
    updated.splice(index, 1);
    setNewTraining({ ...newTraining, participants: updated });
  };

  const handleSave = async () => {
    if (newTraining.id) {
      await updateDoc(doc(db, "trainings", newTraining.id), newTraining);
    } else {
      await addDoc(collection(db, "trainings"), newTraining);
    }
    fetchTrainings();
    setNewTraining({ ...initialState });
    setOpen(false);
  };

  const handleEdit = (row) => {
    setNewTraining(row);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setNewTraining({ ...initialState });
  };

  const handleDelete = async (id) => {
    await deleteDoc(doc(db, "trainings", id));
    fetchTrainings();
  };

  const totalInvestment = trainings.reduce(
    (sum, t) => sum + Number(t.totalInvestmentCL || 0),
    0
  );

  const participationByBranch = {};
  trainings.forEach((t) => {
    t.participants?.forEach((p) => {
      if (!participationByBranch[p.branch]) participationByBranch[p.branch] = 0;
      participationByBranch[p.branch]++;
    });
  });

  const chartData = Object.entries(participationByBranch).map(
    ([name, value]) => ({ name, value })
  );

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return new Intl.DateTimeFormat("ro-RO", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    }).format(date);
  };

  return (
    <Box>
      <Helmet>
        <title>Stagii Adulți - Scouts Cluj</title>
      </Helmet>
      <Typography variant="h4" gutterBottom>
        Stagii Adulți
      </Typography>

      <Tabs value={tab} onChange={(_, val) => setTab(val)}>
        <Tab label="Stagii" />
        <Tab label="Statistici" />
      </Tabs>

      {tab === 0 && (
        <>
          <Button
            variant="outlined"
            onClick={() => setOpen(true)}
            sx={{ m: 2 }}
          >
            Adaugă
          </Button>
          {trainings.map((training) => (
            <Accordion key={training.id} sx={{ mb: 2 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box width="100%">
                  <Typography variant="subtitle1">{training.name}</Typography>
                  <Typography variant="body2">
                    {formatDate(training.date)} • {training.location} • Taxă:{" "}
                    {training.feePerParticipant} RON • Investiție CL:{" "}
                    {training.totalInvestmentCL} RON
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {training.participants?.map((p, i) => {
                  const branchIndex = ramuriDeVarsta.indexOf(p.branch);
                  const branchColor = COLORS[branchIndex % COLORS.length];
                  return (
                    <Typography key={i} variant="body2">
                      • {p.name} (
                      <span style={{ color: branchColor }}>{p.branch}</span>)
                    </Typography>
                  );
                })}
                <Box mt={2}>
                  <IconButton onClick={() => handleEdit(training)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(training.id)}>
                    <DeleteIcon color="error" />
                  </IconButton>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </>
      )}

      {tab === 1 && (
        <Box mt={3}>
          <Typography variant="h6">
            Total investiție CL: {totalInvestment} RON
          </Typography>
          <PieChart width={400} height={300}>
            <Pie
              data={chartData}
              cx="50%"
              cy="50%"
              outerRadius={100}
              fill="#8884d8"
              dataKey="value"
              label
            >
              {chartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </Box>
      )}

      <Dialog open={open} onClose={handleCloseModal} fullWidth maxWidth="md">
        <DialogTitle>
          {newTraining.id ? "Editează" : "Adaugă"} stagiu
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="dense"
            label="Nume stagiu"
            name="name"
            value={newTraining.name}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="dense"
            label="Data"
            name="date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={newTraining.date}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="dense"
            label="Locație"
            name="location"
            value={newTraining.location}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="dense"
            label="Taxă per participant"
            name="feePerParticipant"
            value={newTraining.feePerParticipant}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="dense"
            label="Investiție CL"
            name="totalInvestmentCL"
            value={newTraining.totalInvestmentCL}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="dense"
            label="Observații"
            name="notes"
            value={newTraining.notes}
            onChange={handleChange}
          />

          <Typography variant="h6" sx={{ mt: 2 }}>
            Participanți
          </Typography>
          {newTraining.participants.map((p, i) => (
            <Box key={i} display="flex" gap={2} mb={1}>
              <Autocomplete
                freeSolo
                options={allParticipants.map((ap) => ap.name)}
                value={p.name}
                fullWidth
                onChange={(event, value) => {
                  const existing = allParticipants.find(
                    (ap) => ap.name === value
                  );
                  const updated = [...newTraining.participants];
                  if (existing) {
                    updated[i] = {
                      name: existing.name,
                      branch: existing.branch,
                    };
                  } else if (value) {
                    updated[i] = { name: value, branch: "" };
                  }
                  setNewTraining({ ...newTraining, participants: updated });
                }}
                onInputChange={(event, value) => {
                  const updated = [...newTraining.participants];
                  updated[i].name = value;
                  setNewTraining({ ...newTraining, participants: updated });
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Nume participant" fullWidth />
                )}
              />
              <FormControl fullWidth>
                <InputLabel>Ramura</InputLabel>
                <Select
                  value={p.branch}
                  onChange={(e) =>
                    handleParticipantChange(i, "branch", e.target.value)
                  }
                >
                  {ramuriDeVarsta.map((r) => (
                    <MenuItem key={r} value={r}>
                      {r}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <IconButton onClick={() => removeParticipant(i)}>
                <DeleteIcon color="error" />
              </IconButton>
            </Box>
          ))}
          <Button onClick={addParticipant} sx={{ mt: 1 }}>
            Adaugă participant
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Anulează</Button>
          <Button onClick={handleSave}>Salvează</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TrainingPage;
