import React, { useState, useEffect } from "react";
import { db } from "../../firebaseConfig";
import {
  collection,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const CategoryManagement = ({ reassignCategories }) => {
  const [categories, setCategories] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [newCategory, setNewCategory] = useState({
    name: "",
    keywords: "",
    color: "#000000",
  });
  const [editCategory, setEditCategory] = useState(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    const querySnapshot = await getDocs(
      collection(db, "transactionCategories")
    );
    setCategories(
      querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    );
  };

  const handleOpenModal = (category = null) => {
    setEditCategory(category);
    setNewCategory(
      category
        ? {
            name: category.name,
            keywords: category.keywords.join(", "),
            color: category.color,
          }
        : { name: "", keywords: "", color: "#000000" }
    );
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setEditCategory(null);
  };

  const handleSaveCategory = async () => {
    if (editCategory) {
      await updateDoc(doc(db, "transactionCategories", editCategory.id), {
        name: newCategory.name,
        keywords: newCategory.keywords.split(",").map((kw) => kw.trim()),
        color: newCategory.color,
      });
    } else {
      await addDoc(collection(db, "transactionCategories"), {
        name: newCategory.name,
        keywords: newCategory.keywords.split(",").map((kw) => kw.trim()),
        color: newCategory.color,
      });
    }

    fetchCategories();
    reassignCategories();
    handleCloseModal();
  };

  const handleDeleteCategory = async (id) => {
    await deleteDoc(doc(db, "transactionCategories", id));
    fetchCategories();
  };

  return (
    <Box mt={3}>
      <Typography variant="h6">Categories</Typography>
      <Button
        variant="contained"
        onClick={() => handleOpenModal()}
        sx={{ mb: 2 }}
      >
        Add Category
      </Button>

      {/* List of Categories */}
      {categories.map((category) => (
        <Box
          key={category.id}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={1}
          borderBottom="1px solid #ddd"
        >
          <Box
            sx={{
              width: `250px`,
              marginRight: 15,
            }}
            display="flex"
            alignItems="center"
          >
            <Box
              sx={{
                width: 20,
                height: 20,
                backgroundColor: category.color,
                marginRight: 5,
              }}
            />
            <Typography>{category.name}</Typography>
          </Box>
          <Typography sx={{ flexGrow: 1, mx: 2 }}>
            {category.keywords.join(", ")}
          </Typography>
          <Box>
            <IconButton onClick={() => handleOpenModal(category)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => handleDeleteCategory(category.id)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      ))}

      {/* Add/Edit Category Modal */}
      <Dialog open={modalOpen} onClose={handleCloseModal}>
        <DialogTitle>
          {editCategory ? "Edit Category" : "Add Category"}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Category Name"
            fullWidth
            value={newCategory.name}
            onChange={(e) =>
              setNewCategory({ ...newCategory, name: e.target.value })
            }
            margin="dense"
          />
          <TextField
            label="Keywords (comma-separated)"
            fullWidth
            value={newCategory.keywords}
            onChange={(e) =>
              setNewCategory({ ...newCategory, keywords: e.target.value })
            }
            margin="dense"
          />
          <Box mt={2}>
            <Typography>Pick Color</Typography>
            <input
              type="color"
              value={newCategory.color}
              onChange={(e) =>
                setNewCategory({ ...newCategory, color: e.target.value })
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button onClick={handleSaveCategory} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CategoryManagement;
