import React, { useState, useEffect } from "react";
import { db } from "../../firebaseConfig";

import {
  collection,
  getDocs,
  updateDoc,
  doc,
  addDoc,
  query,
  where,
  Timestamp,
} from "firebase/firestore";
import {
  Box,
  Button,
  Tabs,
  Tab,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
} from "@mui/material";
import CreditTransactions from "./budgetTabs/CreditTransactions";
import DebitTransactions from "./budgetTabs/DebitTransactions";
import Statistics from "./budgetTabs/Statistics";
import CategoryManagement from "./CategoryManagement";
import FileUploader from "../../components/FileUploader";

const BudgetPage = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [allTransactions, setAllTransactions] = useState([]); // Store all transactions
  const [filteredTransactions, setFilteredTransactions] = useState([]); // Apply filters here
  const [categories, setCategories] = useState<any>([]);
  const [files, setFiles] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [openCategoriesModal, setOpenCategoriesModal] = useState(false);
  const [showCategories, setShowCategories] = useState(false);

  useEffect(() => {
    fetchCategories();
    fetchTransactions();
  }, []);

  useEffect(() => {
    fetchTransactions();
  }, [selectedCategory, startDate, endDate]);

  const fetchTransactions = async () => {
    setLoading(true);
    const querySnapshot = await getDocs(
      collection(db, "financialTransactions")
    );
    const transactions = querySnapshot.docs.map((doc: any) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setAllTransactions(transactions);
    setFilteredTransactions(transactions); // Initially, no filters applied
    setLoading(false);
  };

  // Apply filters when category, startDate, or endDate change
  useEffect(() => {
    applyFilters();
  }, [selectedCategory, startDate, endDate, searchTerm, allTransactions]);

  const applyFilters = () => {
    let filtered = [...allTransactions];

    if (selectedCategory !== "all") {
      filtered = filtered.filter((t) => t.category === selectedCategory);
    }

    if (startDate) {
      const start = Timestamp.fromDate(new Date(startDate));
      filtered = filtered.filter(
        (t) => t.date && t.date.toDate() >= start.toDate()
      );
    }

    if (endDate) {
      const end = Timestamp.fromDate(new Date(endDate));
      filtered = filtered.filter(
        (t) => t.date && t.date.toDate() <= end.toDate()
      );
    }

    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      filtered = filtered.filter(
        (t) =>
          t.details.toLowerCase().includes(term) ||
          t.amount.toString().includes(term)
      );
    }

    setFilteredTransactions(filtered);
  };

  const fetchCategories = async () => {
    const querySnapshot = await getDocs(
      collection(db, "transactionCategories")
    );
    setCategories(
      querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    );
  };

  const handleFileUpload = async (event) => {
    const uploadedFiles: any = Array.from(event.target.files);
    if (!uploadedFiles.length) return;
    setLoading(true);
    setFiles(uploadedFiles);

    for (const file of uploadedFiles) {
      const text = await file.text();
      parseMT940(text);
    }
    setLoading(false);
  };

  const parseMT940 = async (text: string) => {
    const transactions: any[] = [];
    const lines = text.split("\n");

    let currentTransaction: any = {};
    let accountNumber: string | null = null;
    let collectingDetails = false; // Track if we're collecting multiline details

    const formatDate = (rawDate: string) => {
      if (rawDate.length === 6) {
        const year = `20${rawDate.substring(0, 2)}`;
        const month = rawDate.substring(2, 4);
        const day = rawDate.substring(4, 6);
        return `${year}-${month}-${day}`;
      }
      return rawDate; // Return as is if format is unexpected
    };

    lines.forEach((line) => {
      line = line.trim();

      if (line.startsWith(":25:")) {
        accountNumber = line.substring(4);
      } else if (line.startsWith(":61:")) {
        // If there's an ongoing transaction, save it before starting a new one
        if (
          Object.keys(currentTransaction).length &&
          currentTransaction.amount > 0
        ) {
          transactions.push(currentTransaction);
        }

        // Start a new transaction
        const match = line.match(
          /(\d{6})(\d{4})?(C|D)([\d,]+)[A-Z]{4}(.{0,34})/
        );
        if (match) {
          const isDebit = match[3] === "D";
          currentTransaction = {
            date: Timestamp.fromDate(new Date(formatDate(match[1]))),
            valueDate: match[2]
              ? Timestamp.fromDate(new Date(formatDate(match[2])))
              : null,
            amount: parseFloat(match[4].replace(",", ".")),
            type: isDebit ? "debit" : "credit",
            details: "",
            accountNumber: accountNumber || "",
          };
          collectingDetails = false; // Reset details collection
        }
      } else if (line.startsWith(":86:")) {
        currentTransaction.details += line.substring(4) + " ";
        collectingDetails = true;
      } else if (collectingDetails) {
        currentTransaction.details += line + " ";
      }
    });

    // Add last transaction if it exists
    if (
      Object.keys(currentTransaction).length &&
      currentTransaction.amount > 0
    ) {
      transactions.push(currentTransaction);
    }

    // Save transactions to database after checking if they exist
    for (const transaction of transactions) {
      const exists = await transactionExists(transaction);
      if (!exists) {
        transaction.category = categorizeTransaction(
          transaction.details.trim()
        );
        await addDoc(collection(db, "financialTransactions"), transaction);
      }
    }

    fetchTransactions();
  };
  const transactionExists = async (transaction) => {
    const q = query(
      collection(db, "financialTransactions"),
      where("date", "==", transaction.date),
      where("amount", "==", transaction.amount),
      where("type", "==", transaction.type)
    );
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  const categorizeTransaction = (details) => {
    for (const category of categories) {
      if (
        category.keywords.some((keyword) =>
          details.toLowerCase().includes(keyword.toLowerCase())
        )
      ) {
        return category.name;
      }
    }
    return "Uncategorized";
  };

  const handleEditCategory = async () => {
    if (selectedTransaction && selectedCategory) {
      await updateDoc(
        doc(db, "financialTransactions", selectedTransaction.id),
        {
          category: selectedCategory,
        }
      );
      setOpen(false);
      fetchTransactions();
    }
  };

  const openCategoryModal = (transaction) => {
    setSelectedTransaction(transaction);
    setSelectedCategory(transaction.category || "");
    setOpen(true);
  };

  const reassignCategoriesToTransactions = async () => {
    console.log("Reassigning categories to transactions...");

    // Fetch latest transactions
    const transactionSnapshot = await getDocs(
      collection(db, "financialTransactions")
    );
    const transactions: any = transactionSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Fetch latest categories
    const categorySnapshot = await getDocs(
      collection(db, "transactionCategories")
    );
    const categories: any = categorySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    for (const transaction of transactions) {
      // Find the best matching category based on keywords
      let newCategory = "Uncategorized";
      for (const category of categories) {
        if (
          category.keywords.some((keyword) =>
            transaction.details.toLowerCase().includes(keyword.toLowerCase())
          )
        ) {
          newCategory = category.name;
          break; // Assign the first matching category
        }
      }

      // Only update Firestore if the category actually changes
      if (transaction.category !== newCategory) {
        console.log(
          `Updating transaction ${transaction.id} from '${transaction.category}' to '${newCategory}'`
        );

        await updateDoc(doc(db, "financialTransactions", transaction.id), {
          category: newCategory,
        });
      }
    }

    // Refresh transactions in the UI
    fetchTransactions();
  };

  return (
    <Box>
      <Typography variant="h4">Budget Management</Typography>
      <FileUploader handleFileUpload={handleFileUpload} />
      {loading && <CircularProgress />}
      <Box display="flex" justifyContent="flex-start" gap={2} mt={2}>
        <TextField
          label="Search"
          type="text"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ width: "200px" }}
        />
        <FormControl sx={{ width: "150px" }}>
          <InputLabel>Category</InputLabel>
          <Select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <MenuItem value="all">All Categories</MenuItem>
            {categories.map((category) => (
              <MenuItem key={category.id} value={category.name}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Start Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <TextField
          label="End Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
        <Button
          variant="contained"
          onClick={() => setShowCategories((prev) => !prev)}
        >
          {showCategories ? "Hide Categories" : "Manage Categories"}
        </Button>
      </Box>
      {showCategories && (
        <CategoryManagement reassignCategories={fetchTransactions} />
      )}

      <Tabs value={tabIndex} onChange={(e, newIndex) => setTabIndex(newIndex)}>
        <Tab label="Debits" />
        <Tab label="Credits" />
        <Tab label="Statistics" />
      </Tabs>

      {tabIndex === 0 && (
        <DebitTransactions
          transactions={filteredTransactions}
          openCategoryModal={openCategoryModal}
          categories={categories}
          searchTerm={searchTerm}
        />
      )}
      {tabIndex === 1 && (
        <CreditTransactions
          transactions={filteredTransactions}
          openCategoryModal={openCategoryModal}
          categories={categories}
          searchTerm={searchTerm}
        />
      )}
      {tabIndex === 2 && (
        <Statistics
          transactions={filteredTransactions}
          categories={categories}
        />
      )}

      {/* Category Management Modal */}
      <Dialog
        open={openCategoriesModal}
        onClose={() => setOpenCategoriesModal(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <CategoryManagement reassignCategories={fetchTransactions} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default BudgetPage;
