import React from "react";
import {
  DataGrid,
  GridRenderEditCellParams,
  GridRowModel,
} from "@mui/x-data-grid";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebaseConfig"; // <-- Make sure this import path is correct
import EditCategoryCell from "./EditCategoryCell";
import {
  formatDateForDisplay,
  highlightSearch,
} from "../../../components/utils/utils";

const DebitTransactions = ({ transactions, categories, searchTerm }: any) => {
  // Filter only debit transactions
  const debitTransactions = transactions.filter((t: any) => t.type === "debit");

  // Columns with 'editable: true' on the category field
  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 120,
      valueGetter: (params) => formatDateForDisplay(params.value),
    },
    { field: "amount", headerName: "Amount", width: 120 },
    {
      field: "details",
      headerName: "Details",
      width: 1000,
      renderCell: (params) => (
        <span
          dangerouslySetInnerHTML={{
            __html: highlightSearch(params.value, searchTerm),
          }}
        />
      ),
    },
    {
      field: "category",
      headerName: "Category",
      width: 150,
      editable: true,
      renderCell: (params) => {
        const category = categories.find((cat) => cat.name === params.value);
        const backgroundColor = category
          ? `${category.color}40` // Adds transparency (40 = 25% opacity)
          : "transparent";

        return (
          <div
            style={{
              backgroundColor,
              padding: "4px",
              borderRadius: "4px",
              width: "100%",
              textAlign: "center",
            }}
          >
            {params.value}
          </div>
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <EditCategoryCell {...params} categories={categories} />
      ),
    },
  ];

  const processRowUpdate = async (
    newRow: GridRowModel,
    oldRow: GridRowModel
  ) => {
    if (newRow.category !== oldRow.category) {
      await updateDoc(doc(db, "financialTransactions", newRow.id), {
        category: newRow.category,
      });
    }
    return newRow; // Ensure the updated row is returned to the grid
  };

  return (
    <DataGrid
      rows={debitTransactions}
      columns={columns}
      autoHeight
      // Enable cell editing
      editMode="cell"
      // Let the grid process row updates
      processRowUpdate={processRowUpdate}
      // Optionally, handle errors
      onProcessRowUpdateError={(error) => {
        console.error("Error updating row:", error);
      }}
    />
  );
};

export default DebitTransactions;
